<template>
  <v-card rounded="lg" class="bg-white py-4">
    <!-- <p class="text-uppercase text-h4">upload document</p> -->
    <p class="text-h5">
      Upload Document
    </p>
    <p class="font-weight-bold">
      API ini digunakan untuk proses unggah dokumen yang akan diproses untuk
      ditandatangani dan ditambahkan materai.
    </p>
    <div class="col-md-8 ps-0">
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Body Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Field
              </th>
              <th class="text-left text-white">
                Data Type
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Informasi Umum
              </th>
              <th class="text-left text-white">
                Contoh
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in body" :key="item.key">
              <td>{{ item.field }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.info }}</td>
              <td>{{ item.ex }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="curl">
            <pre>
curl --location -g --request POST '{{ base_url }}/v1/document/upload' \
--header 'X-Secret-Key: {{ secret_key }}' \
--form 'document=@"/Users/username/Downloads/sample.pdf"' \
--form 'document_title="file"'
</pre
            >
          </vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
    "status": 200,
    "message": "success",
    "validation": {},
    "data": {
        "document_id": 8849
    }
}
          </pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "multipart/form-data"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-sandbox.momofin.com/v1/document/upload"
        }
      ],
      head: [
        {
          key: "X-Secret-Key",
          val: "",
          mandatory: "Ya",
          length: " ",
          desc: "X-Secret-Key dapatkan di dashboard momofingo"
        }
      ],
      body: [
        {
          field: "document_title",
          type: "String",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "document",
          type: "file/pdf",
          mandatory: "Ya",
          length: "",
          info: "",
          ex: ""
        }
      ],
      resp: [
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ]
    };
  }
};
</script>
