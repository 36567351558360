<template>
  <v-card rounded="lg" class="bg-white py-4">
    <!-- <p class="text-uppercase text-h4">upload document</p> -->
    <p class="text-h5">
      Detail Document
    </p>
    <p class="font-weight-bold">
      API ini digunakan untuk mendatapatkan informasi dokumen.
    </p>
    <div class="col-md-8 ps-0">
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Request Method
              </th>
              <th class="text-left text-white">
                POST
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tab" :key="item.req">
              <td>{{ item.req }}</td>
              <td>{{ item.post }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="font-weight-bold mb-1">Header Parameter</p>
      <v-simple-table class="border-primary">
        <template v-slot:default>
          <thead style="background: #0a3564;">
            <tr>
              <th class="text-left text-white">
                Key
              </th>
              <th class="text-left text-white">
                Value
              </th>
              <th class="text-left text-white">
                Mandatory
              </th>
              <th class="text-left text-white">
                Length
              </th>
              <th class="text-left text-white">
                Deskripsi
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in head" :key="item.key">
              <td>{{ item.key }}</td>
              <td>{{ item.val }}</td>
              <td>{{ item.mandatory }}</td>
              <td>{{ item.length }}</td>
              <td>{{ item.desc }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="col-md-8 ps-0">
      <p class="text-h5">Request & Response Payload Sample</p>
      <p class="font-weight-bold mb-1">Request</p>
      <v-card class="pa-2" outlined>
        <p>
<vue-code-highlight lang="curl">
<pre>
curl --location -g --request GET '{{base_url}}/v1/document/detail/8827' \
--header 'X-Secret-Key: {{secret_key}}'
</pre>
</vue-code-highlight>
        </p>
      </v-card>
      <p class="font-weight-bold mb-1">Normal Response</p>
      <v-card class="pa-2" outlined>
        <p>
          <vue-code-highlight lang="json"
            ><pre>
{
    "status": 200,
    "message": "success",
    "validation": {},
    "data": {
        "id": 8827,
        "document_title": "testing sekali ahay",
        "status": "completed",
        "description": "Dokumen telah Selesai",
        "created_at": "2023-02-10T10:30:15.000Z"
    }
}
          </pre
            ></vue-code-highlight
          >
        </p>
      </v-card>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: [
        {
          req: "Input Format",
          post: "application/json"
        },
        {
          req: "Output Format",
          post: "application/json"
        },
        {
          req: "Endpoint",
          post: "https://api-sandbox.momofin.com/v1/document/detail/:id"
        }
      ],
      head: [
        {
          key: "X-Secret-Key",
          val: "",
          mandatory: "Ya",
          length: " ",
          desc: "X-Secret-Key dapatkan di dashboard momofingo"
        }
      ],
      body: [

      ],
      resp: [
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        },
        {
          field: "",
          type: "",
          mandatory: "",
          length: "",
          info: "",
          ex: ""
        }
      ]
    };
  }
};
</script>
