<template>
  <div>
    <Upload />
    <Stamp />
    <Detail />
  </div>
</template>
<script>
import Upload from "./partials/1.upload.vue";
import Stamp from "./partials/2.stamp.vue";
import Detail from "./partials/3.detail.vue";

export default {
  components: { Upload, Stamp, Detail },
  setup: () => {}
};
</script>
